import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Titlebar = _resolveComponent("Titlebar")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxCustomRule = _resolveComponent("DxCustomRule")!
  const _component_SmartTableV2 = _resolveComponent("SmartTableV2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Titlebar, { title: "Einstellungen" }),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_SmartTableV2, {
          tableId: "settings",
          ref: "settings",
          dataStore: _ctx.dataStore,
          "hide-add-button": true,
          "hide-delete-button": true,
          "hide-function-column": true,
          key: "key"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxColumn, {
              "data-field": "key",
              caption: "Key",
              visible: false,
              "allow-editing": false,
              "data-type": "string"
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "pluginKey",
              caption: "Plugin",
              "data-type": "string"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLookup, {
                  "data-source": _ctx.pluginLookupDataSource,
                  "value-expr": "key",
                  "display-expr": "pluginName"
                }, null, 8, ["data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "name",
              caption: "Name",
              "data-type": "string"
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "defaultValue",
              caption: "Standardwert",
              "allow-editing": false,
              "data-type": "string"
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "value",
              caption: "Wert",
              "data-type": "string"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxCustomRule, {
                  "validation-callback": _ctx.validateValue,
                  message: "Eingabe entspricht nicht dem gewählten Datentyp oder ist ungültig."
                }, null, 8, ["validation-callback"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "dataType",
              caption: "Datentyp"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLookup, {
                  "data-source": _ctx.dataTypeLookupDataSource,
                  "value-expr": "key",
                  "display-expr": "value"
                }, null, 8, ["data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "isGlobal",
              caption: "Gobal",
              "data-type": "boolean"
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "createdAt",
              caption: "Erstellt",
              "allow-editing": false,
              "data-type": "datetime"
            }),
            _createVNode(_component_DxColumn, {
              "data-field": "modifiedAt",
              caption: "Letzte Anpassung",
              "allow-editing": false,
              "data-type": "datetime"
            })
          ]),
          _: 1
        }, 8, ["dataStore"]))
      : _createCommentVNode("", true)
  ]))
}