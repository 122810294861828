
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';
import { DipODataStore, AlertService, GenericErrorHandler, SmartTableV2, Titlebar } from 'rey-web-toolkit';
import { DxButton, DxColumn, DxLookup, DxCustomRule } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Container } from 'typescript-ioc';
import SettingsDataTypeEnum from '@/models/SettingsDataTypeEnum';
import ISetting from '@/models/ISetting';
import IPlugin from '@/models/IPlugin';

@Options({
  components: {
    DxColumn,
    DxLookup,
    DxButton,
    DxCustomRule,
    SmartTableV2,
    Titlebar,
    axios,
  },
})
export default class Settings extends Vue {
  private isLoading = true;
  private alertService: AlertService = Container.get(AlertService);
  public dataStore: any | undefined = undefined;

  public async beforeCreate() {
    document.title = 'System - Einstellungen';
    this.dataStore = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/Setting`,
          key: 'key',
          keyType: 'Guid',
          deserializeDates: false,
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: 'pluginKey',
    };

    this.isLoading = false;
  }

  public pluginLookupDataSource = {
    store: new CustomStore({
      key: 'key',
      loadMode: 'raw',
      load: async () => {
        return (await axios.get('/odata/Plugin')).data.value as IPlugin[];
      },
    }),
    sort: 'pluginName',
  };

  public dataTypeLookupDataSource = {
    store: {
      type: 'array',
      data: Object.entries(SettingsDataTypeEnum).map(([key, value]) => ({ key, value })),
      key: 'key',
    },
    sort: 'value',
  };

  public initNewRow(e: { data: ISetting }): void {
    e.data.defaultValue = '';
    e.data.isGlobal = false;
  }

  public validateValue(e: { value: string; data: ISetting }) {
    switch (e.data.dataType) {
      case SettingsDataTypeEnum.String:
        return true;
      case SettingsDataTypeEnum.Number:
        return !isNaN(Number(e.value));
      case SettingsDataTypeEnum.Bool:
        return e.value.toLowerCase() === 'true' || e.value.toLowerCase() === 'false';
      case SettingsDataTypeEnum.Json:
        return this.isValidJson(e.value);
    }
  }

  private isValidJson(jsonString: string) {
    try {
      JSON.parse(jsonString);
    } catch (e) {
      return false;
    }
    return true;
  }
}
